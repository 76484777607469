import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-eugene-oregon.png'
import image0 from "../../images/cities/scale-model-of-museum-of-natural-and-cultural-history-in-eugene-oregon.png"
import image1 from "../../images/cities/scale-model-of-oregon-air-and-space-museum-in-eugene-oregon.png"
import image2 from "../../images/cities/scale-model-of-shelton-mcmurphey-johnson-house-in-eugene-oregon.png"
import image3 from "../../images/cities/scale-model-of-gerlinger-lounge-in-eugene-oregon.png"
import image4 from "../../images/cities/scale-model-of-lane-county-history-museum-in-eugene-oregon.png"
import image5 from "../../images/cities/scale-model-of-eugene,-cascades-&-coast-visitor-center---travel-lane-county-in-eugene-oregon.png"
import image6 from "../../images/cities/scale-model-of-r-v-caravan-tours-in-eugene-oregon.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Eugene'
            state='Oregon'
            image={image}
            lat='44.0567'
            lon='-123.1162'
            attractions={ [{"name": "Museum of Natural and Cultural History", "vicinity": "1680 E 15th Ave, Eugene", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.0430232, "lng": -123.06805529999997}, {"name": "Oregon Air and Space Museum", "vicinity": "90377 Boeing Dr, Eugene", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.115156, "lng": -123.21250910000003}, {"name": "Shelton-McMurphey-Johnson House", "vicinity": "303 Willamette St, Eugene", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.0563996, "lng": -123.092149}, {"name": "Gerlinger Lounge", "vicinity": "1526-1598 University St, Eugene", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.0436086, "lng": -123.0752943}, {"name": "Lane County History Museum", "vicinity": "740 W 13th Ave, Eugene", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.04510800000001, "lng": -123.1037589}, {"name": "Eugene, Cascades & Coast Visitor Center - Travel Lane County", "vicinity": "754 Olive St, Eugene", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 44.051465, "lng": -123.09447399999999}, {"name": "R V Caravan Tours", "vicinity": "Eugene", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 44.05, "lng": -123.09000000000003}] }
            attractionImages={ {"Museum of Natural and Cultural History":image0,"Oregon Air and Space Museum":image1,"Shelton-McMurphey-Johnson House":image2,"Gerlinger Lounge":image3,"Lane County History Museum":image4,"Eugene, Cascades & Coast Visitor Center - Travel Lane County":image5,"R V Caravan Tours":image6,} }
       />);
  }
}